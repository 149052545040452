@charset "UTF-8";
@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-down-and-out {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(20px); } }

@keyframes fade-forward-inout {
  0% {
    opacity: 0;
    transform: translateZ(500px); }
  50% {
    opacity: 1;
    transform: translateZ(0px); }
  100% {
    opacity: 0;
    transform: translateZ(-500px); } }

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-up-and-out {
  0% {
    opacity: 1;
    transform: translateY(0px); }
  100% {
    opacity: 0;
    transform: translateY(-20px); } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes pulse {
  0% {
    background-color: #3ac2d0; }
  30% {
    background-color: #2db0be; }
  50% {
    background-color: #3ac2d0; }
  70% {
    background-color: #2db0be; }
  100% {
    background-color: #3ac2d0; } }

@keyframes highlight {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@keyframes pulse-scale {
  0% {
    transform: scale(1.02); }
  30% {
    transform: scale(1); }
  50% {
    transform: scale(1.02); }
  70% {
    transform: scale(1); }
  100% {
    transform: scale(1.02); } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'DIN';
  src: url("https://uppdateradinvarldsbild.se/fonts/din-regular-webfont.eot");
  src: local("â˜º"), url("https://uppdateradinvarldsbild.se/fonts/din-regular-webfont.woff") format("woff"), url("https://uppdateradinvarldsbild.se/fonts/din-regular-webfont.ttf") format("truetype"), url("https://uppdateradinvarldsbild.se/fonts/din-regular-webfont.svg") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'DIN';
  src: url("https://uppdateradinvarldsbild.se/fonts/din-bold-webfont.eot");
  src: local("â˜º"), url("https://uppdateradinvarldsbild.se/fonts/din-bold-webfont.woff") format("woff"), url("https://uppdateradinvarldsbild.se/fonts/din-bold-webfont.ttf") format("truetype"), url("https://uppdateradinvarldsbild.se/fonts/din-bold-webfont.svg") format("svg");
  font-weight: 600;
  font-style: normal; }

* {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body,
input,
textarea,
select {
  color: #ffffff;
  font-family: 'DIN', sans-serif;
  font-size: 24px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

html,
body,
#root,
.Core {
  position: relative;
  height: 100%;
  width: 100%;
  background: #3ac2d0;
  transition: background 1s ease;
  overflow: hidden; }
  html.Purple,
  body.Purple,
  #root.Purple,
  .Core.Purple {
    background: #ce8ec0; }

b,
strong {
  font-weight: 600; }

i {
  font-weight: 600; }

hr {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 35%, white 65%, rgba(255, 255, 255, 0) 100%);
  display: block;
  width: 100%;
  height: 3px;
  margin: 40px 0;
  border: none; }
  @media screen and (max-width: 1000px), screen and (max-height: 600px) {
    hr {
      height: 2px;
      margin: 20px 0; } }

p {
  margin: 30px 0; }
