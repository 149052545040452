
@import "../../../Import/SCSS/classes";

$size: 26px;

.ErrorCounter {

    position: relative;
    margin: -5px 0 0 -5px;
    white-space: nowrap;
    z-index: 1;

    .Dot {

        display: inline-block;
        position: relative;
        width: $size;
        height: $size;
        margin: 5px;
        border-radius: $size;
        background: $color-blue-dark;

        &:after {

            display: block;
            position: absolute;
            width: $size;
            height: $size;
            border-radius: $size;
            background: $color-magenta;
            opacity: 0;
            transform: scale(0);
            transition: all .5s ease; 
            content: "";

        }

        &.Filled:after {

            opacity: 1;
            transform: scale(1);

        }

    }

}