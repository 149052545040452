
@import "../../../Import/SCSS/classes";
@import "../../../Import/SCSS/fireworks";

$duration: .5s;
$timing: ease;

.Highscore {

    @include page;

    overflow: hidden;

    h2 {

        padding: 0 0 60px;

    }

    &.Fireworks {

        @include fireworks;

    }

}

.HighscoreItem {

    position: absolute;
    right: 0;
    left: 0;
    font-weight: 700;
    text-align: left;
    transition: all $duration $timing;
    animation: fade-up .5s ease;

    &.Highlight {

        @include highlight;

    }

    &.Live {

        @include gradient-line;

        color: $color-purple;
        z-index: 10;

        &:after {

            display: none;

        }

    }

    &.Out {

        animation: fade-down-and-out .5s ease forwards;

    }

    &:after {

        @include gradient-line;

        display: block;
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        height: 2px;
        content: "";

    }

}

.HighscoreItemContent {

    position: relative;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 50px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.HighscorePlace {

    display: inline-block;
    position: relative;
    width: 50px;

}

.HighscorePoints {

    position: absolute;
    top: calc( 50% - 12px );
    right: 0;

}

.HighscoreWrapper {

    position: relative;
    transition: all $duration $timing;

}