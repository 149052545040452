
@import "../../../Import/SCSS/classes";

.Sida {

    display: inline-block;
    position: relative;
    vertical-align: middle;

    svg {

        display: block;
        width: 100%;
        height: 100%;
        fill: $color-white;

    }

}