
@mixin active {

    &:active {

        @content;

    }

}

@mixin hover {

    &:hover {

        @content;

    }

}

@mixin input-placeholder {

	&::-webkit-input-placeholder {
	  
		@content;
	
	}
	
	&:-moz-placeholder {
	  
		@content;
	
	}
	
	&::-moz-placeholder {
	  
		@content;
	
	}
	
	&:-ms-input-placeholder {
	  
		@content;
	
	}

}

@mixin no-select {

    user-select: none;
    -webkit-touch-callout: none;

}

@mixin overflow {

	overflow: auto;
    -webkit-overflow-scrolling: touch;

}

@mixin overlay {

	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

}

@mixin responsive-width( $width ) {
	
	@media screen and ( max-width: $width ) {
	
		@content;
		
	}
	
}

@mixin responsive-height( $height ) {
	
	@media screen and ( max-height: $height ) {
	
		@content;
		
	}
	
}

@mixin responsive-width-and-height( $width, $height ) {
	
	@media screen and ( max-width: $width ) and ( max-height: $height ) {
	
		@content;
		
	}
	
}

@mixin responsive-width-or-height( $width, $height ) {
	
	@media
	screen and ( max-width: $width ),
	screen and ( max-height: $height ) {
	
		@content;
		
	}
	
}

@mixin responsive-ratio( $ratio ) {
	
	@media screen and ( min-aspect-ratio: $ratio ) {
	
		@content;
		
	}
	
}

@mixin responsive-ratio-and-width( $ratio, $width ) {
	
	@media screen and ( min-aspect-ratio: $ratio ) and ( max-width: $width ) {
	
		@content;
		
	}
	
}

@mixin responsive-ratio-and-height( $ratio, $height ) {
	
	@media screen and ( min-aspect-ratio: $ratio ) and ( max-height: $height ) {
	
		@content;
		
	}
	
}

@mixin responsive-ratio-and-width-and-height( $ratio, $width, $height ) {
	
	@media screen and ( min-aspect-ratio: $ratio ) and ( max-width: $width ) and ( max-height: $height ) {
	
		@content;
		
	}
	
}

@mixin text-overflow {

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

@mixin vendor-prefix( $name, $argument ) {
	
	#{$name}: #{$argument};
	-webkit-#{$name}: #{$argument};
	-moz-#{$name}: #{$argument};
	-ms-#{$name}: #{$argument};
	-o-#{$name}: #{$argument};
	
}
