
@import "../../../Import/SCSS/classes";

.Button {

    @include no-select;

    $size: 130px;

    display: inline-block;
    position: relative;
    width: $size;
    height: ( $size + 4px );
    margin: 0 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: $size;
    text-align: center;
    text-transform: uppercase;
    background: $color-green;
    background: radial-gradient( circle at 80%, $color-green, $color-green-dark 70%, $color-green-light 100% );
    border-radius: $size;
    box-shadow: 0px 0px 15px rgba( 0, 0, 0, .35 );
    cursor: pointer;
    transform: scale( 1.02 );
    transition: all $duration-button ease;

    .ButtonContent {

        display: block;
        position: relative;
        border: 2px solid rgba( 0, 0, 0, .15 );
        border-radius: $size;
        z-index: 1;
        transition: all $duration-button ease;


    }

    .Icon {

        display: inline-block;
        width: 60px;
        height: 60px;
        vertical-align: middle;

        svg {

            position: relative;
            top: -15px;

        }

        & + span {

            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            font-size: 14px;
            line-height: 14px;

        }

    }

    &:before {

        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: radial-gradient( rgba( 255, 255, 255, .9 ), rgba( 255, 255, 255, .6 ) 10%, rgba( 255, 255, 255, .3 ) 20%, rgba( 255, 255, 255, 0 ) 60% );
        border-radius: $size;
        content: "";
        transition: opacity $duration-button ease;

    }

    &.Selected {

        box-shadow: 0px 0px 20px rgba( 0, 0, 0, .25 );
        transform: scale( 1.06 );

    }

    &.Active {

        box-shadow: 0px 0px 10px rgba( 0, 0, 0, .4 );
        transform: scale( .95 );

        &:before {

            opacity: .6;

        }

    }

    &.Disabled {

        box-shadow: 0px 0px 10px rgba( 0, 0, 0, .4 ) !important;
        transform: scale( 1 ) !important;

        .ButtonContent {

            color: $color-green-dark;
            opacity: .5;

        }

        &:before {

            opacity: 0 !important;

        }

    }

    &.Black,
    &.Slant {

        $border-radius: 5px;
        $padding: 10px;
        $inset: $size - 2 * $padding;

        width: $inset;
        height: ( $inset + 4px );
        padding: $padding;
        line-height: $inset;
        background: $color-gray;
        border-radius: $border-radius;

        .ButtonContent {

            border: none;
            border-radius: $border-radius - 1px;
            box-shadow: inset 0 0 20px rgba( 255, 255, 255, .2 );

        }

        &:after {
            
            @include overlay;

            display: block;
            border-radius: $border-radius;
            box-shadow: inset 0 0 60px rgba( 0, 0, 0, 1 );
            content: "";

        }

        &:before {

            $bleed: 1px;

            top: $padding + $bleed;
            right: $padding + $bleed;
            bottom: $padding + $bleed;
            left: $padding + $bleed;
            border-radius: $border-radius - 1px - $bleed;
            background: linear-gradient( rgba( 255, 255, 255, 1 ), rgba( 255, 255, 255, 0 ) );
            opacity: .25 !important;

        }

        &.Active {

            .ButtonContent {

                opacity: .8;

            }

            &:before {

                opacity: 0 !important;

            }

        }

        &.Disabled {

            .ButtonContent {

                color: $color-gray-dark;

            }

            &:before {

                opacity: .1 !important;

            }

        }

    }

    &.Red {

        background: radial-gradient( circle at 80%, $color-magenta, $color-magenta-dark 70%, $color-magenta-light 100% );

        &.Disabled {

            .ButtonContent {

                color: $color-magenta-dark;

            }

        }

    }

    &.Slant {

        z-index: 100;
        transform: scale( 1.02 ) rotate( 45deg );

        &:before {

            background: linear-gradient( 135deg, rgba( 255, 255, 255, 1 ), rgba( 255, 255, 255, 0 ) );

        }

        &.Selected {

            transform: scale( 1.06 ) rotate( 45deg );

        }

        &.Active {

            transform: scale( .95 ) rotate( 45deg );

        }

        &.Disabled {

            transform: scale( 1 ) rotate( 45deg ) !important;

        }

        .ButtonContent {

            padding: 5px;

        }

        .Icon {

            display: block;
            width: 100%;
            height: 100%;
            transform: rotate(-45deg);

            svg {

                top: 0;

            }

        }

    }

}