
@import "../../../Import/SCSS/classes";

.Logo {

    display: inline-block;
    position: relative;
    width: 44px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;

    svg {

        display: block;
        width: 100%;
        height: 100%;
        fill: $color-white;

    }

}

.LogoText {

    display: inline-block;
    position: relative;
    vertical-align: middle;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    &.Big {

        max-width: none;
        font-size: 36px;
        line-break: 40px;

    }

}