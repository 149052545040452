
@import "../../../Import/SCSS/classes";

@mixin menu-item-active {

    &:before {

        opacity: .95;

    }

    span {

        transform: scale( 1 );

    }

}

@mixin menu-item-hover {

    &:before {

        opacity: 1;

    }

    span {

        color: $color-blue;
        transform: scale( 1.1 );

        .GameDone & {

            color: $color-purple;

        }

    }

}

.Navigation {

    @include fade-up;
    @include no-select;

    text-align: center;

}

.NavigationItem {

    position: relative;
    max-width: 600px;
    margin: 0 auto 2px;
    font-weight: 700;
    line-height: 120px;
    cursor: pointer;

    span {

        display: inline-block;
        position: relative;
        transition: color $duration-button ease, transform $duration-button-double ease;
        
    }

    &:after {

        @include gradient-line;

        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: 2px;
        content: "";

    }

    &:before {

        @include gradient-line;
        @include overlay;

        opacity: 0;
        transition: opacity $duration-button ease;
        content: "";

    }

    &:last-child:after {

        display: none;

    }

    &.Selected {

        @include menu-item-hover;

    }

    &.Active {

        @include menu-item-active;

    }

    @include hover {

        @include menu-item-hover;

    }

    @include active {

        @include menu-item-active;

    }

}