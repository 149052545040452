
@import "../../../Import/SCSS/classes";

.Game {

    position: relative;
    height: 100%;
    overflow: hidden;

}

.GameCounter {

    position: absolute !important;
    right: $margin-normal;
    bottom: $margin-normal;

}

.GameDone {

    background: $color-purple;

}

.GameDone,
.GameQuestion,
.GameStart {

    @include page;

}

.GameDoneError {

    position: relative;

    h3 {

        @include title-mid;

        max-width: 500px;
        margin: 36px auto;

    }

    p {

        max-width: 500px;
        margin: 30px auto;

    }

    :first-child {

        margin-top: 0 !important;

    }

    :last-child {

        margin-bottom: 0 !important;

    }

    @include text-narrow;

}

.GameDoneNoErrors {

    @include content;

    & > p:first-child {

        max-width: 500px;

    }

    .WebVersion & {

        margin-bottom: 0;

    }

}

.GameDoneShare {

    position: relative;
    margin: 0;

    .ShareButton {

        margin: 20px;

    }

}

.GameDoneSource {

    @include citation;

    position: relative;
    top: -20px;

}

.GameErrorCounter {

    $sub: 3px;

    position: absolute !important;
    bottom: $margin-normal - $sub;
    left: $margin-normal - $sub;

}

.GameStartRules {

    @include content;

}

.GameStartRulesText {

    @include text;

}

.GameQuestion {

    h3 {

        max-width: 500px;
        margin: 36px auto !important;

    }

    &.Answered {

        @include fade-up-and-out;

    }

    &.Pulse {

        &:before {

            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            content: "";

            @include pulse;

        }

        .CenterContent {

            @include pulse-scale;

        }

    }

}

.GameQuestionText {

    position: relative;

}

.ReadySetGo {

    $width: 300px;
    $height: 100px;

    position: relative;
    perspective: 500px;
    width: $width;
    height: $height;
    margin: 0 auto;

    div {

        @include overlay;

        line-height: $height;
        text-align: center;
        font-size: 36px;
        font-weight: bold;
        opacity: 0;

    }

    &.Ready .ReadySetGoReady,
    &.Set .ReadySetGoSet,
    &.Go .ReadySetGoGo {

        animation: fade-forward-inout .75s linear;

    }

}

.ReadySetGoContainer {

    overflow: hidden;

}